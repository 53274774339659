import { Box, Stack, useTheme } from "@mui/material";


import { tokens } from "../../theme";
import Header from "../../components/Header";
import GlobalLiveSearch from "../../components/GlobalLiveSearch/GlobalLiveSearch";

const GlobalSearch = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Search" subtitle="Global search." />
            </Box>
            {/* CONTEXT */}
            <GlobalLiveSearch />
        </Box>
    )
};

export default GlobalSearch;