import {
    Box,
    Typography,
    useTheme,
    Badge,
    Divider,
    Avatar,
    Stack,
    IconButton
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import InfoDataGrid from "../../components/InfoDataGrid";
import DiagnosticDialog from "../../components/DiagnosticDialog";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import {useEffect, useState} from "react";
import { tokens } from "../../theme";
import BillingService from "../../services/BillingService";
import { int2ip } from "../../utils/formatIpAddress"
import { fToNow, fTime } from "../../utils/formatTime";
import PersonalAccount from "./PersonalAccount";

const User = ({ billingId, userId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [billingUser, setBillingUser] = useState({
        actual_address: 'loading.....',
        basic_account: 'loading.....',
        comments: 'loading.....',
        email: 'loading.....',
        full_name: 'loading.....',
        home_telephone: 'loading.....',
        login: 'loading.....',
        mobile_telephone: 'loading.....',
        user_id: 'loading.....',
        work_telephone: 'loading.....',
    })

    const [billingUserAccounts, setBillingUserAccounts] = useState([])
    const [billingUserIpServices, setBillingUserIpServices] = useState([])
    const [billingUserSwitchesInfo, setBillingUserSwitchesInfo] = useState([])

    const [expanded, setExpanded] = useState("personalAccounts");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const columnsPersonalAccounts = [
        { field: 'account_id', headerName: 'ID' },
        { field: 'balance', headerName: 'Balance' },
        { field: 'block_id', headerName: 'block_id' },
        { field: 'credit', headerName: 'Credit' },
        { field: 'int_status', headerName: 'int_status' },
        { field: 'unlimited', headerName: 'unlimited' },
    ];
    const columnsIpServices = [
        {
            field: 'ip',
            headerName: 'IP',
            flex: 1,
            renderCell: ({ row: { ip, service_name, comment } }) => {
                return (
                    <Box>
                        <Typography>
                            {ip}
                        </Typography>
                        <Typography>
                            { service_name }({ comment })
                        </Typography>
                    </Box>
                )
            }
        },
        { field: 'speed', headerName: 'Speed' },
        { field: 'mac', headerName: 'Mac' },
    ];

    const columnsSwitchesInfo = [
        {
            field: 'arp_entry',
            headerName: 'Arp entry',
            flex: 1,
            renderCell: ({ row: { arp_ip, arp_time , gateway} }) => {
                return (
                    <Box>
                        <Typography>
                            { int2ip(arp_ip) }
                        </Typography>
                        <Typography>
                            { int2ip(gateway) }({ arp_time })
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'fdb_entry',
            headerName: 'Fdb entry',
            flex: 1,
            renderCell: ({row: {mac, fdb_time} }) => {
                return (
                    <Box>
                        <Typography>
                            {mac}
                        </Typography>
                        <Typography>
                            {fdb_time}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'switch',
            headerName: 'Switch',
            flex: 1,
            renderCell: ({row: {sw_ip, sw_port}}) => {
                return (
                    <Box>{ int2ip(sw_ip) }:{sw_port}</Box>
                )
            }
        },
        {
            field: 'diagnostic',
            headerName: 'Diagnostic',
            renderCell: ({row: {fdb_network, sw_ip, sw_port, arp_network, arp_ip, gateway}}) => {
                return (
                    <IconButton onClick={() => {handleOpen({
                        "fdb_network": fdb_network,
                        "sw_ip": sw_ip,
                        "sw_port": sw_port,
                        "arp_network": arp_network,
                        "arp_ip" : arp_ip,
                        "gateway": gateway
                    })}} aria-label="delete" size="large">
                        <SettingsOutlinedIcon/>
                    </IconButton>
                )
            }
        },
    ];

    useEffect(() => {
        const fetcher = async () => {
            const responseBillingUser = await BillingService.billing_user(billingId, userId);
            setBillingUser(responseBillingUser.data)
            const responseBillingUserAccounts = await BillingService.billing_user_accounts(billingId, userId);
            setBillingUserAccounts(responseBillingUserAccounts.data.accounts)
            const responseBillingUserIpServices = await BillingService.billing_user_ip_services(billingId, userId);
            setBillingUserIpServices(responseBillingUserIpServices.data.ip_services)
            setBillingUserSwitchesInfo(responseBillingUserIpServices.data.switches_information)
        };
        fetcher()
    }, [billingId, userId])

    function getRowId(row) {
        return row.account_id
    }

    const [open, setOpen] = useState(false);
    const [paramsDiagnostic, setParamsDiagnostic] = useState({});

    const handleOpen = (params) => {
        setParamsDiagnostic(params)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const StyledChatBox = styled(Box)(({ theme }) => ({
        "&:hover": {
            cursor: "pointer",
        },
    }));
    
    const StyledBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: "#44b700",
            color: "#44b700",
            boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                animation: "ripple 1.2s infinite ease-in-out",
                border: "1px solid currentColor",
                content: '""',
            },
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1,
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0,
            },
        },
    }));

    return (
        <Stack
            sx={{
                width: "100%", flexGrow: 1, overflowY: "scroll" 
            }}
        >
            {/* MODAL Diagnostic */}
            <DiagnosticDialog
                open={open}
                handleClose={handleClose}
                params={paramsDiagnostic}
            >
            </DiagnosticDialog>
            <StyledChatBox
                sx={{
                    width: "100%",
                    borderRadius: 1,
                    // backgroundColor: id == selectedChatId
                    //     ? theme.palette.mode === "light"
                    //         ? alpha(theme.palette.primary.main, 0.5)
                    //         : theme.palette.primary.main
                    //     : theme.palette.mode === "light"
                    //         ? "#fff"
                    //         : theme.palette.background.paper,
                }}
                p={2}
            >
                <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                >
                    <Stack direction="row" spacing={2}>
                        {" "}
                        
                        <Avatar
                            alt="User"
                            src={"img"}
                            sx={{
                                position: "static"
                            }}
                        />
                        
                        <Stack spacing={0.3}>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                sx={{ color: colors.grey[100] }}
                            >
                                ID: {userId}
                            </Typography>
                            <Typography variant="caption">
                                Login: {billingUser.login}
                            </Typography>
                            <Typography variant="caption">
                                {billingUser.full_name}
                            </Typography>
                            
                        </Stack>
                    </Stack>
                    <Stack spacing={2} alignItems={"center"}>
                        <Typography sx={{ fontWeight: 600 }} variant="caption">
                            update_on
                        </Typography>
                        {/* <Badge
                            className="unread-count"
                            color="primary"
                            badgeContent="changed"
                        /> */}
                    </Stack>
                </Stack>
                <Divider />
                <Stack spacing={1} m>
                    <Stack>
                        <Typography variant="h5"><LocationOnIcon />Location:</Typography>
                        <Typography variant="h5">{billingUser.actual_address}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="h5"><EmailIcon />E-mail:</Typography>
                        <Typography variant="h5">{billingUser.email}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="h5"><PhoneIcon />Mobile telephone:</Typography>
                        <Typography variant="h5">{billingUser.mobile_telephone}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="h5"><PhoneIcon />Home telephone:</Typography>
                        <Typography variant="h5">{billingUser.home_telephone}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="h5"><PhoneIcon />Work telephone:</Typography>
                        <Typography variant="h5">{billingUser.work_telephone}</Typography>
                    </Stack>
                    <Divider />
                </Stack>
                <Stack>
                    <Typography variant="h5"><CommentIcon />Comments:</Typography>
                    <Typography variant="h5">{billingUser.comments}</Typography>
                    <Divider />
                </Stack>
                <Stack>
                    <Typography variant="h5"><GroupsIcon />Groups:</Typography>
                    <Typography variant="h5">No groups</Typography>
                    <Divider />
                </Stack>
                <Stack>
                    <Typography variant="h5"><CurrencyRubleIcon />Personal accounts:</Typography>
                    {billingUserAccounts.map((el) => {
                        return <PersonalAccount {...el} />;
                    })}
                    <Divider />
                </Stack>
                <Stack>
                    <Accordion expanded={expanded === 'ipservices'} onChange={handleChange('ipservices')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Technical information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfoDataGrid
                            columns={columnsIpServices}
                            rows={billingUserIpServices}
                            // getRowId={getRowId()}
                        />
                        <InfoDataGrid
                            columns={columnsSwitchesInfo}
                            rows={billingUserSwitchesInfo}
                            // getRowId={getRowId()}
                        />
                    </AccordionDetails>
                </Accordion>
                </Stack>
            </StyledChatBox>
        </Stack>
    );
};

export default User;