import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InfoBox from "../../components/InfoBox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ContactBox from "../../components/ContactBox";
import {useEffect, useState} from "react";
import InfoDataGrid from "../../components/InfoDataGrid";
import DiagnosticDialog from "../../components/DiagnosticDialog";
import BillingService from "../../services/BillingService";
import {useParams} from "react-router-dom";

function int2ip (ipInt) {
    return ( (ipInt>>>24) +'.' + (ipInt >> 16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
}

const BillingUser = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const params = useParams()
    const [billingUser, setBillingUser] = useState({
        actual_address: 'loading.....',
        basic_account: 'loading.....',
        comments: 'loading.....',
        email: 'loading.....',
        full_name: 'loading.....',
        home_telephone: 'loading.....',
        login: 'loading.....',
        mobile_telephone: 'loading.....',
        user_id: 'loading.....',
        work_telephone: 'loading.....',
    })

    const [billingUserAccounts, setBillingUserAccounts] = useState([])
    const [billingUserIpServices, setBillingUserIpServices] = useState([])
    const [billingUserSwitchesInfo, setBillingUserSwitchesInfo] = useState([])




    const [expanded, setExpanded] = useState("personalAccounts");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const columnsPersonalAccounts = [
        { field: 'account_id', headerName: 'ID' },
        { field: 'balance', headerName: 'Balance' },
        { field: 'block_id', headerName: 'block_id' },
        { field: 'credit', headerName: 'Credit' },
        { field: 'int_status', headerName: 'int_status' },
        { field: 'unlimited', headerName: 'unlimited' },
    ];
    const columnsIpServices = [
        {
            field: 'ip',
            headerName: 'IP',
            flex: 1,
            renderCell: ({ row: { ip, service_name, comment } }) => {
                return (
                    <Box>
                        <Typography>
                            {ip}
                        </Typography>
                        <Typography>
                            { service_name }({ comment })
                        </Typography>
                    </Box>
                )
            }
        },
        { field: 'speed', headerName: 'Speed' },
        { field: 'mac', headerName: 'Mac' },
    ];

    const columnsSwitchesInfo = [
        {
            field: 'arp_entry',
            headerName: 'Arp entry',
            flex: 1,
            renderCell: ({ row: { arp_ip, arp_time , gateway} }) => {
                return (
                    <Box>
                        <Typography>
                            { int2ip(arp_ip) }
                        </Typography>
                        <Typography>
                            { int2ip(gateway) }({ arp_time })
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'fdb_entry',
            headerName: 'Fdb entry',
            flex: 1,
            renderCell: ({row: {mac, fdb_time} }) => {
                return (
                    <Box>
                        <Typography>
                            {mac}
                        </Typography>
                        <Typography>
                            {fdb_time}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'switch',
            headerName: 'Switch',
            flex: 1,
            renderCell: ({row: {sw_ip, sw_port}}) => {
                return (
                    <Box>{ int2ip(sw_ip) }:{sw_port}</Box>
                )
            }
        },
        {
            field: 'diagnostic',
            headerName: 'Diagnostic',
            renderCell: ({row: {fdb_network, sw_ip, sw_port, arp_network, arp_ip, gateway}}) => {
                return (
                    <IconButton onClick={() => {handleOpen({
                        "fdb_network": fdb_network,
                        "sw_ip": sw_ip,
                        "sw_port": sw_port,
                        "arp_network": arp_network,
                        "arp_ip" : arp_ip,
                        "gateway": gateway
                    })}} aria-label="delete" size="large">
                        <SettingsOutlinedIcon/>
                    </IconButton>
                )
            }
        },
    ];

    useEffect(() => {
        const fetcher = async () => {
            const responseBillingUser = await BillingService.billing_user(params.billingid, params.userid);
            setBillingUser(responseBillingUser.data)
            const responseBillingUserAccounts = await BillingService.billing_user_accounts(params.billingid, params.userid);
            setBillingUserAccounts(responseBillingUserAccounts.data.accounts)
            const responseBillingUserIpServices = await BillingService.billing_user_ip_services(params.billingid, params.userid);
            setBillingUserIpServices(responseBillingUserIpServices.data.ip_services)
            setBillingUserSwitchesInfo(responseBillingUserIpServices.data.switches_information)
        };
        fetcher()
    }, [])

    function getRowId(row) {
        return row.account_id
    }

    const [open, setOpen] = useState(false);
    const [paramsDiagnostic, setParamsDiagnostic] = useState({});

    const handleOpen = (params) => {
        setParamsDiagnostic(params)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Billing User" subtitle="Billing User information" />
            </Box>
            {/* MODAL Diagnostic */}
            <DiagnosticDialog
                open={open}
                handleClose={handleClose}
                params={paramsDiagnostic}
            >
            </DiagnosticDialog>
            {/* CONTEXT */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                // gridAutoRows="240px"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InfoBox
                        title={billingUser.user_id}
                        subtitle={billingUser.login}
                        info={billingUser.full_name}
                        icon={
                            <PersonOutlinedIcon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InfoBox
                        title="ffffff"

                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ContactBox
                        contacts={[
                            {
                                icon: EmailIcon,
                                value: billingUser.email
                            },
                            {
                                icon: CallIcon,
                                value: billingUser.home_telephone
                            },
                            {
                                icon: CallIcon,
                                value: billingUser.mobile_telephone
                            },
                            {
                                icon: CallIcon,
                                value: billingUser.work_telephone
                            },
                            {
                                icon: HomeIcon,
                                value: billingUser.actual_address
                            }
                        ]}
                    />
                </Box>
                {/* ROW 2 */}
            </Box>
            <Box
                // gridColumn="span 12"
                backgroundColor={colors.primary[400]}
                // display="flex"
                alignItems="center"
                justifyContent="center"
                mt="20px"
                // p="20px"
            >
                <Accordion expanded={expanded === 'personalAccounts'} onChange={handleChange('personalAccounts')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Personal accounts
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfoDataGrid
                            columns={columnsPersonalAccounts}
                            rows={billingUserAccounts}
                            getRowId={getRowId}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'ipservices'} onChange={handleChange('ipservices')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Technical information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfoDataGrid
                            columns={columnsIpServices}
                            rows={billingUserIpServices}
                            // getRowId={getRowId()}
                        />
                        <InfoDataGrid
                            columns={columnsSwitchesInfo}
                            rows={billingUserSwitchesInfo}
                            // getRowId={getRowId()}
                        />
                    </AccordionDetails>
                </Accordion>



            </Box>
        </Box>
    )
};

export default BillingUser;