import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Table,
    List,
    ListItem,
    Divider,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack, InputBase
} from "@mui/material";
import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useCallback, useEffect, useState, Fragment, useContext} from "react";
import {Link, useNavigate} from 'react-router-dom';
import AppealService from "../../services/AppealService";
import LinearProgress from '@mui/material/LinearProgress';
import {Search} from "@mui/icons-material";
import ChatComponent from "./Chat";
import {Context} from "../../index";
import NoChat from "./NoAppeal";


const Appeal = ({ onLoading }) => {

    const {store} = useContext(Context);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const appealId = store.appealId?.toString();

    const [appeal, setAppeal] = useState({
        'comments': []
    })

    useEffect(() => {
        subscribe()
    }, [ appealId ])

    const subscribe = async () => {
        try {
            const {data} = await AppealService.getAppeal(appealId);
            setAppeal(data);
            onLoading(data);
            console.log(data)
            // const appealsUpdate = await AppealService.appealsUpdate(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0])
            // subscribe()
        } catch (e) {
            setTimeout( () => {
                // subscribe()
            }, 500)
        }
    }

    return (
        <Box
            sx={{
                minWidth: "650px"
            }}
            backgroundColor={colors.primary[400]}
        >
            {appealId ? (
                <ChatComponent 
                    appeal={ appeal }
                />
            ) : (
            <Stack
                spacing={2}
                sx={{ height: "100%", width: "100%" }}
                alignItems="center"
                justifyContent={"center"}
            >
                <NoChat />
                <Typography variant="subtitle2">
                    Select a conversation or start a{" "}
                    <Link
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                        }}
                        to="/"
                    >
                        new one
                    </Link>
                </Typography>
            </Stack>
            )}
        </Box>
    )
};

export default Appeal;