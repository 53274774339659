import {Avatar, Box, Badge, Stack, Divider, ListItemButton, ListItemAvatar, ListItemText, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import {Fragment, useContext} from "react";
// import ProgressCircle from "./ProgressCircle";
import { styled, alpha } from "@mui/material/styles";
import {Context} from "../../index";
import truncateString from "../../utils/trancate";
import { fToNow, fTime } from "../../utils/formatTime";


const StyledChatBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const AppealListElement = ({ source_object, source, source_type, updated_on, id, last_comment, is_open, changed, appeal_id, handleAppealClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {store} = useContext(Context);
    const selectedChatId = store.appealId?.toString();
    let isSelected = +selectedChatId === id;

    if (!selectedChatId) {
        isSelected = false;
    }
    return (
        <StyledChatBox
            onClick={() => {
                handleAppealClick(id);
            }}
            sx={{
                width: "100%",

                borderRadius: 1,

                backgroundColor: id == selectedChatId
                    ? theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main
                    : theme.palette.mode === "light"
                        ? "#fff"
                        : theme.palette.background.paper,
            }}
            p={2}
        >
        <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
        >
            <Stack direction="row" spacing={2}>
                {" "}
                {is_open ? (
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        variant="dot"
                    >
                        <Avatar alt={source} src={"img"} />
                    </StyledBadge>
                ) : (
                    <Avatar alt={source} src={"img"} />
                )}
                <Stack spacing={0.3}>
                    {source_object ? (
                        <Typography variant="subtitle2">{source_object.name}</Typography>
                    ) : (
                        <Typography variant="subtitle2">{source}</Typography>
                    )
                }
                    <Typography variant="caption">{truncateString(last_comment, 20)}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={2} alignItems={"center"}>
                <Typography sx={{ fontWeight: 600 }} variant="caption">
                    { fTime(updated_on) }
                </Typography>
                <Badge
                    className="unread-count"
                    color="primary"
                    badgeContent={changed}
                />
            </Stack>
        </Stack>
        </StyledChatBox>
    );
};

export default AppealListElement;