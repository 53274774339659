import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Table,
    List,
    ListItem,
    Divider,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack, InputBase
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useCallback, useEffect, useState, Fragment, useContext} from "react";
import {Link, useNavigate} from 'react-router-dom';
import AppealService from "../../services/AppealService";
import LinearProgress from '@mui/material/LinearProgress';
import {Search} from "@mui/icons-material";
// import AppealListElement from "../../components/AppealListElement";
// import ChatComponent from "./Chat";
import {Context} from "../../index";
// import NoChat from "./NoAppeal";
import SearchIcon from "@mui/icons-material/Search";
import LiveSearch from "../../components/LiveSearch/LiveSearch";
import Appeals from "../../components/Appeals/Appeals";
import Appeal from "../../components/Appeal/Appeal";
import GlobalLiveSearch from "../../components/GlobalLiveSearch/GlobalLiveSearch";

function int2ip (ipInt) {
    return ( (ipInt>>>24) +'.' + (ipInt >> 16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
}

const AppAppeals = () => {
    const {store} = useContext(Context);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()

    const [appealId, setAppealId] = useState(store.appealId)
    const [search, setSearch] = useState('')
    const [content, setContent] = useState({})

    // useEffect(() => {
    //     console.log("AppUse")
    // }, [appealId])

    const handleAppealClick = (appeal_id) => {
        store.setAppealId(appeal_id)
        setAppealId(appeal_id)
    };

    const handleAppealLoading = ( appeal ) => {
        setSearch(appeal.source)
        if (appeal.source_type == 'incoming_call') {
            setContent({
                "object": "user",
                "billing_id": appeal.source_object.billing_id,
                "user_id": appeal.source_object.user_id
            })
        }
        else {
            setContent({})
        }
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Appeals" subtitle="Appeals" />
            </Box>
            {/* CONTEXT */}
            <Stack direction="row" alignItems="stretch" sx={{ width: "100%", height: "80vh"}} spacing={1.5}>
                {/* appeals */}
                <Appeals
                    onAppealClick={handleAppealClick}
                />
                {/* appeals */}

                {/*chat*/}
                <Appeal 
                    appealId={appealId}
                    onLoading={handleAppealLoading}
                />
                {/*chat*/}
                
                {/* info */}
                <GlobalLiveSearch 
                    // value={search}
                    content={content}
                />
                {/* info */}
            </Stack>
        </Box>
    )
};

export default AppAppeals;