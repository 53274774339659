import {
    useTheme
} from "@mui/material";

import { tokens } from "../../theme";
import NothingSelected from "./NothingSelected";
import Host from "../Host/Host";
import User from "../User/User";

const Content = ({ content=null }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const renderContent = () => {
        if (content) {
            switch(content.object) {
                case 'user':
                    return <User billingId={content.billing_id} userId={content.user_id}/>
                case 'host': 
                    return <Host networkId={content.network_id} hostIp={content.host} />;
                default: 
                    return <NothingSelected/>;
                }
        }
        else {
            return <NothingSelected/>;
        }
    }

    return (
        <>
            {renderContent()}
        </>
    );
};

export default Content;