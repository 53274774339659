import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Table,
    List,
    ListItem,
    Divider,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack, InputBase
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useCallback, useEffect, useState, Fragment, useContext} from "react";
import {Link, useNavigate} from 'react-router-dom';
import AppealService from "../../services/AppealService";
import ProviderService from "../../services/ProviderService";
import LinearProgress from '@mui/material/LinearProgress';
import {Search} from "@mui/icons-material";
import AppealListElement from "./AppealListElement";
// import ChatComponent from "./Chat";
import {Context} from "../../index";
// import NoChat from "./NoAppeal";
import SearchIcon from "@mui/icons-material/Search";
import LiveSearch from "../LiveSearch/LiveSearch";
import { el } from "date-fns/locale";
// import GlobalLiveSearch from "../../GlobalLiveSearch/GlobalLiveSearch";

const Appeals = ({ onAppealClick }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [providers, setProviders] = useState([]);
    const [selectProvider, setSelectProvider] = useState(0)

    const [appealGroups, setAppealGroups] = useState([])
    const [selectAppealGroups, setSelectAppealGroups] = useState(0)

    const [status, setStatus] = useState(1);

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0)

    const [isUpdate, setUpdate] = useState(false)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
    });
    const [filterModel, setFilterModel] = useState({
        provider: 0,
        group: 0,
        open: 1,
        close: 0,
        quickFilterValues: '',
    })

    

    const handleChangeProvider = (event, newValue) => {
        setSelectProvider(newValue)   
        filterModel.provider = newValue  
        setFilterModel(filterModel)
    };

    const handleChangeGroup = (event, newValue) => {
        setSelectAppealGroups(newValue)
        filterModel.group = newValue
        setFilterModel(filterModel)
    };

    const handleChangeStatus = (event, newValue) => {
        setStatus(newValue)
        switch(newValue) {
            case 0:
                filterModel.open = 1
                filterModel.close = 1
                setFilterModel(filterModel)
                break
            case 1:
                filterModel.open = 1
                filterModel.close = 0
                setFilterModel(filterModel)
                break
            case 2:
                filterModel.open = 0
                filterModel.close = 1
                setFilterModel(filterModel)
                break
        }
        // setUpdate(true)
    };

    useEffect(() => {
        if (isUpdate) {
            subscribe()
            setUpdate(false)
        }
    }, [isUpdate])

    useEffect(() => {
        subscribe()
    }, [paginationModel, filterModel])

    const subscribe = async () => {
        try {
            const providers = await ProviderService.getProviders();
            setProviders(providers.data.providers)
            const appealGroups = await AppealService.getAppealGroup();
            setAppealGroups(appealGroups.data.appeal_groups)
            const {data} = await AppealService.getAppeals(paginationModel.page, paginationModel.pageSize, filterModel);
            setRows(data.appeals);
            setRowCount(data.totalCount)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const subscribe_update = async () => {
            if (localStorage.getItem('token')) {
                try {
                    setUpdate(true)
                    const appealsUpdate = await AppealService.appealsUpdate()
                    subscribe_update()
                } catch (e) {
                    setTimeout( () => {
                        // setUpdate(false)
                        subscribe_update()
                    }, 500)
                }
            }
        };
        
        subscribe_update()
    }, [])

    return (
        <Box
            sx={{
                position: "relative",
                // height: "100%",
                minWidth: "320px",
                width: true ? 320 : "100vw",
                backgroundColor:
                    theme.palette.mode === "light"
                        ? "#F8FAFF"
                        : theme.palette.background,

                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
        >

            <Stack spacing={2} sx={{ maxHeight: "100%"}}>
                <Stack sx={{ width: "100%" }}>
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="3px"
                        m={1}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                    {providers.length > 0
                        ? <Box>
                            <Tabs
                                value={selectProvider}
                                onChange={handleChangeProvider}
                                // variant="scrollable"
                                // scrollButtons="auto"
                                aria-label="disabled tabs example"
                            >
                                <Tab value={0} label="All" />
                                {providers.map((el) => {
                                    return <Tab value={ el.id } label={ el.name } />;
                                })}
                            </Tabs>
                        </Box>
                        : <></>
                    }
                    {appealGroups.length > 0
                        ? <Box>
                            <Tabs
                                value={selectAppealGroups}
                                onChange={handleChangeGroup}
                                // variant="scrollable"
                                // scrollButtons="auto"
                                aria-label="disabled tabs example"
                            >
                                <Tab value={0} label="All" />
                                {appealGroups.map((el) => {
                                    return <Tab value={ el.id } label={ el.name } />;
                                })}
                            </Tabs>
                        </Box>            
                        : <></>
                    }       
                    <Box>
                        <Tabs
                            value={status}
                            onChange={handleChangeStatus}
                            // variant="scrollable"
                            // scrollButtons="auto"
                            aria-label="disabled tabs example"
                        >
                            <Tab label="All" />
                            <Tab label="Open" />
                            <Tab label="Close" />
                        </Tabs>
                    </Box>
                </Stack>
                <Stack sx={{ flexGrow: 1, overflowY: "scroll" }}>
                    <Stack spacing={1}>
                        {rows.map((el) => {
                            return <AppealListElement {...el} handleAppealClick={ onAppealClick }/>;
                        })}
                        </Stack>
                </Stack>
            </Stack>
        </Box>
    )
};

export default Appeals;