import {Avatar, Box, Badge, Stack, Divider, ListItemButton, ListItemAvatar, ListItemText, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import {Fragment, useContext, useState} from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// import ProgressCircle from "./ProgressCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled, alpha } from "@mui/material/styles";
import {Context} from "../../index";
import truncateString from "../../utils/trancate";
import { fToNow, fTime } from "../../utils/formatTime";

const StyledChatBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const PersonalAccount = ({ account_id, balance, block_id, block_type, credit, int_status, unlimited }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [expanded, setExpanded] = useState("personalAccounts");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <StyledChatBox
            // onClick={() => {
            //     handleAppealClick(id);
            // }}
            sx={{
                width: "100%",

                borderRadius: 1,

                backgroundColor: 0
                    ? theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main
                    : theme.palette.mode === "light"
                        ? "#fff"
                        : theme.palette.background.paper,
            }}
            // p={2}
        >
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems={"center"}
                        justifyContent="space-between"
                    >
                        <Typography
                            borderRadius={1}
                            p={0.5}
                            backgroundColor={colors.greenAccent[500]}
                        >
                            {account_id}
                        </Typography>
                        <Stack>
                            <Typography
                                color={int_status
                                    ? colors.greenAccent[500]
                                    : colors.redAccent[500]
                                }
                                variant="h5"
                            >
                                {balance} ₽ (Credit: {credit} ₽)
                            </Typography>
                            <Typography color={block_id === 0
                                    ? colors.greenAccent[500]
                                    : colors.redAccent[500]
                                }
                                variant="h5"
                            >
                                {block_type === null
                                    ? "Активен"
                                    : block_type === 1
                                        ? "Системная блокировка"
                                        : block_type === 2
                                            ? "Административная блокировка"
                                            : block_type === 2
                                                ? "Пользовательская блокировка"
                                                : "Unknown"
                                }               
                            </Typography>
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                        Tariff
                </AccordionDetails>
            </Accordion>
        </StyledChatBox>
    );
};

export default PersonalAccount;