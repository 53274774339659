import {
    Box,
    useTheme,
    Stack
} from "@mui/material";
import LiveSearch from "../LiveSearch/LiveSearch";
import Content from "./Content";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";

const GlobalLiveSearch = ({ value="", content=null }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [openObject, setOpenObject] = useState({})

    const handleClick = (object) => {
        setOpenObject(object)
    };

    useEffect(() => {
        
        setOpenObject(content)
        
    },[content]);

    return (
        <Box
            sx={{
                // position: "relative",
                width: "100%",
                minWidth: "320px",
                // width: true ? 320 : "100vw",
                backgroundColor:
                    theme.palette.mode === "light"
                        ? "#F8FAFF"
                        : theme.palette.background,

                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
        >
            <Stack spacing={2} sx={{ maxHeight: "100%"}}>
                    <Stack sx={{ width: "100%"}}>
                        <LiveSearch 
                            value={value}
                            handleClick={handleClick}
                        />
                    </Stack>
                    <Content 
                        content={openObject}
                    />
                </Stack>
        </Box>
    );
};

export default GlobalLiveSearch;